import * as React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

import {
  CONTAINERS,
  SCREENS
} from "../utils/constants";

import Layout from "../components/Layout/Layout";
import ArticleHero from "../components/Hero/ArticleHero";
import SEO from "../components/SEO/SEO";


export const query = graphql`
  query Legal($uid: String) {
    allPrismicLegal(
      filter: {
        uid: {
          eq: $uid
        }
      }
    ) {
      edges {
        node {
          data {
            legal_text {
              raw
            }
            legal_title {
              text
            }
            meta_title {
              text
            }
            meta_description {
              text
            }
            last_updated(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;


const Section = styled.section`
  width: 100%;
  color: #231F20;
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 2rem 0 3rem;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 2rem 0 4rem;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 2rem 0 5rem;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    margin: 0 auto;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    margin: 0 auto;
  }
`;


const LegalTemplate = ({
  data
}) => {
  if (!data) return null;

  const document = data.allPrismicLegal.edges[0].node.data;

  const legalMeta = {
    metaTitle: document.meta_title.text,
    metaDescription: document.meta_description.text,
  };

  const legalContent = {
    legalTitle: document.legal_title.text,
    legalText: document.legal_text.raw,
  };

  return (
    <Layout
      isLandingPage={ false }
      headerTextColor="#231F20"
    >
      <SEO
        title={ legalMeta.metaTitle }
        description={ legalMeta.metaDescription }
      />

      <ArticleHero
        articleTitle={ legalContent.legalTitle }
      />

      <Section>
        <Container>
          <RichText render={ legalContent.legalText } />
        </Container>
      </Section>
    </Layout>
  )
}


export default LegalTemplate;
